import * as React from 'react';
import DialogBackdrop from 'components/dialog/dialogBackdrop';
import styled from 'styled-components';
import { Form, Field, H1, Button } from 'components/element';
import { useTranslation } from 'react-i18next';
import color from 'styles/color';
import CustomerInput from 'components/transaction/CustomerInput';
import useBookingForm from 'hooks/useBookingForm';
import OutletServiceInput from 'components/transaction/OutletServiceInput';
import OutletProductsInput from 'components/transaction/OutletProductsInput';
import OutletStylistWithTimeInput, {
  FormField,
} from 'components/booking/OutletStylistWithTimeInput';
import { FormikResult } from '../../../../typings/formik';
import { OutletStylist } from 'repositories/outlet';

interface Props {
  onClose: () => void;
  date: Date;
}

export default function AddBooking(props: Props) {
  const { t } = useTranslation();
  const [stylist, setStylist] = React.useState<OutletStylist | undefined>(
    undefined,
  );

  const { onClose } = props;

  const { formik, isLoading, bookingTypes, setIsLoading } = useBookingForm({
    date: props.date,
    onSuccess() {
      onClose();
    },
  });

  return (
    <DialogBackdrop onClose={onClose} cancelable={false}>
      <Panel>
        <Form formik={formik}>
          <Title>{t('common.new_booking')}</Title>
          <BookingContent>
            <CustomerInput
              formik={formik}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            <Field
              isSearchable={false}
              type="select"
              name="type"
              options={bookingTypes}
            />
            <OutletServiceInput
              selectedStylist={stylist}
              values={formik.values.services}
              onChange={(services) =>
                formik.setFieldValue('services', services)
              }
            />
            <OutletProductsInput
              values={formik.values.products}
              onChange={(products) =>
                formik.setFieldValue('products', products)
              }
            />
            <OutletStylistWithTimeInput
              formik={(formik as unknown) as FormikResult<FormField>}
              date={props.date}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              onChange={(stylist) => {
                formik.setFieldValue('stylist', stylist?.id);
                setStylist(stylist);
              }}
            />
            <Button
              type="submit"
              isLoading={isLoading}
              disabled={!formik.isValid}
            >
              {t('booking.add_booking')}
            </Button>
          </BookingContent>
        </Form>
        <CloseButton onClick={onClose}>x</CloseButton>
      </Panel>
    </DialogBackdrop>
  );
}

const Title = styled(H1)`
  text-align: center;
  margin-bottom: 1.5rem;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${color.white};
  color: ${color.black};
  font-size: 36px;
  width: 40px;
  line-height: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  outline: none;
  border: none;
  appearance: none;
`;

export const Panel = styled.div`
  min-width: 28%;
  background-color: white;
  border-radius: 5px;
  min-height: 300px;
  max-height: 90vh;
  position: relative;
  padding: 1rem;
  overflow-y: scroll;

  @media (max-width: 1023px) {
    width: 70%;
  }
`;

const BookingContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
