import * as React from 'react';
import DialogBackdrop from 'components/dialog/dialogBackdrop';
import styled from 'styled-components';
import { H1, H4, P, H3, Form, Button } from 'components/element';
import { useTranslation } from 'react-i18next';
import color from 'styles/color';
import { useQueryGetBooking } from 'repositories/booking/booking.query';
import {
  useMutationChangeBookingStatus,
  useMutationSetBookingSheet,
} from 'repositories/booking/booking.mutation';
import { useApolloClient } from '@apollo/client';
import LoadingView from 'components/LoadingView';
import { useStateGetCurrentOutlet } from 'repositories/outlet';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import OutletStylistWithTimeInput, {
  FormField,
} from 'components/booking/OutletStylistWithTimeInput';
import { FormikResult } from '../../../../typings/formik';

interface Props {
  bookingData: any;
  bookingId: string;
  date: string;
  onClose: () => void;
}

interface Form {
  isRandomStylist: boolean;
  stylist: {
    label: string;
    value: string;
  } | null;
  startMinute: {
    label: string;
    value: number;
  } | null;
}

interface CancelInterface {
  cancelNote: string;
  isShowing: boolean;
}

const initialCancelState = {
  cancelNote: '',
  isShowing: false,
};

const YupSchema = Yup.object().shape({
  outletId: Yup.string().required(),
  booking: Yup.object(),
  stylist: Yup.string().required(),
  time: Yup.number().required(),
  timeInMinutes: Yup.number().required(),
  isRandomStylist: Yup.boolean().required(),
  isStylistFetch: Yup.boolean().required(),
  isOperationalHour: Yup.boolean().required(),
});

export default function UnProcessedBookingDetail(props: Props) {
  const { t } = useTranslation();
  const apolloClient = useApolloClient();

  const [isLoading, setIsLoading] = React.useState(false);
  const [cancelState, setCancelState] = React.useState<CancelInterface>(
    initialCancelState,
  );

  const { bookingId, onClose, date, bookingData } = props;

  const stylistRef = React.useRef<any>(null);
  const { data, loading } = useQueryGetBooking(bookingId);
  const [setBookingStatus] = useMutationChangeBookingStatus();
  const [setBookingSheet] = useMutationSetBookingSheet();

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';
  const booking = data ? data.booking.data : bookingData;

  const formik = useFormik({
    initialValues: {
      outletId,
      booking,
      services: (booking ? booking.services : []) as any,
      stylist: '',
      time: undefined,
      timeInMinutes: 0,
      isRandomStylist: booking.isRandomStylist || false,
      isStylistFetch: false,
      isOperationalHour: false,
    },
    validateOnMount: true,
    validationSchema: YupSchema,
    onSubmit: async (values) => {
      const response = window.confirm(t('common.confirmation_change_text'));
      if (response) {
        try {
          setIsLoading(true);
          await setBookingSheet({
            variables: {
              payload: {
                stylist_id: values.stylist,
                booking_id: bookingId,
                start_minute: values.time,
                is_random_stylist: values.isRandomStylist,
              },
            },
          });

          onClose();
          apolloClient.reFetchObservableQueries();
        } catch (e) {
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  React.useEffect(() => {
    let time = 0;
    if (booking) {
      console.log(booking.stylist);
      booking.services &&
        // eslint-disable-next-line array-callback-return
        booking.services.map((service: any) => {
          time += service.timeInMinutes;
        }, []);

      formik.setValues({
        ...formik.values,
        booking: booking,
        services: booking.services,
        timeInMinutes: time,
        stylist: booking.stylist ? booking.stylist.id : '',
      });
      if (booking.stylist) {
        stylistRef?.current?.setSelectedStylist({
          value: booking.stylist.id,
          label: booking.stylist.name,
        });
      }
    }
  }, [booking]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickCancel = React.useCallback(async () => {
    setCancelState((prev: CancelInterface) => ({
      ...prev,
      isShowing: true,
    }));
  }, []);

  const onConfirmCancel = React.useCallback(async () => {
    // if (await authorizeWithTotp()) {
    try {
      setIsLoading(true);
      await setBookingStatus({
        variables: {
          bookingId: bookingId,
          payload: {
            status: 9,
            cancel_note: cancelState.cancelNote,
          },
        },
      });
      onClose();
      apolloClient.reFetchObservableQueries();
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
    // } else {
    // toast.error(t('common.wrong_pin'));
    // }
  }, [
    apolloClient,
    bookingId,
    cancelState.cancelNote,
    onClose,
    setBookingStatus,
  ]);

  const onCancelNoteChange = React.useCallback((e) => {
    e.persist();
    setCancelState((prev: CancelInterface) => ({
      ...prev,
      cancelNote: e.target.value,
    }));
  }, []);

  const buttonContent = React.useMemo(() => {
    if (cancelState.isShowing) {
      return (
        <>
          <H3Title>{t('common.cancel_reason')}</H3Title>
          <CancelNoteTextArea
            placeholder={t('common.write_reason')}
            name="cancelReason"
            onChange={onCancelNoteChange}
            value={cancelState.cancelNote}
          />
          <ButtonPad />
          <ConfirmCancelButton
            type="button"
            onClick={onConfirmCancel}
            disabled={!cancelState.cancelNote}
          >
            {t('common.confirm_cancel')}
          </ConfirmCancelButton>
        </>
      );
    } else {
      return (
        <>
          <ButtonPad />
          <Button
            type="submit"
            disabled={isLoading || !formik.isValid}
            isLoading={isLoading || formik.isSubmitting}
          >
            {t('common.save')}
          </Button>
          <ButtonPad />
          <OutlineButton
            type="button"
            onClick={onClickCancel}
            isLoading={isLoading}
          >
            {t('common.cancel')}
          </OutlineButton>
        </>
      );
    }
  }, [
    cancelState.cancelNote,
    cancelState.isShowing,
    formik.isSubmitting,
    formik.isValid,
    isLoading,
    onCancelNoteChange,
    onClickCancel,
    onConfirmCancel,
    t,
  ]);

  return (
    <DialogBackdrop onClose={onClose} cancelable={false}>
      <Panel>
        <Form formik={formik}>
          <Title>{t('common.booking_detail')}</Title>
          <Content>
            {formik.values.booking && (
              <>
                <ButtonPad />
                <H4Title>{t('common.name')}</H4Title>
                <P>{formik.values.booking.member.name}</P>
                <H4Title>{t('common.phone_number')}</H4Title>
                <P>{formik.values.booking.member.phoneNumber || '-'}</P>
                <H4Title>{t('common.email')}</H4Title>
                <P>{formik.values.booking.member.email || '-'}</P>
                <H3Title>{t('common.service')}</H3Title>
                {formik.values.booking.services.map((service: any) => {
                  return (
                    <Item>{`${
                      service.service ? service.service.name : service.name
                    }`}</Item>
                  );
                })}
                {formik.values.booking.products &&
                  !!formik.values.booking.products.length && (
                    <H3Title>{t('common.product')}</H3Title>
                  )}
                {formik.values.booking.products.map((product: any) => {
                  return (
                    <Item>{`${product.qty ? product.qty + 'x' : ''} ${
                      product.product ? product.product.name : product.name
                    }`}</Item>
                  );
                })}
                <Divider />
                <OutletStylistWithTimeInput
                  ref={stylistRef}
                  date={new Date(date)}
                  formik={(formik as unknown) as FormikResult<FormField>}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  usePending={false}
                  onChange={(value) => {
                    formik.setFieldValue('stylist', value?.id);
                  }}
                />
                {buttonContent}
              </>
            )}
            <LoadingView isOpen={loading} block />
            <CloseButton onClick={onClose} disabled={isLoading}>
              x
            </CloseButton>
          </Content>
        </Form>
      </Panel>
    </DialogBackdrop>
  );
}

const ButtonPad = styled.div`
  margin-bottom: 1rem;
`;

const OutlineButton = styled(Button)`
  background-color: ${color.white};
  color: ${color.black};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  background-color: ${color.white};
  right: 0;
  padding-bottom: 1px;
  color: black;
  line-height: 40px;
  font-size: 36px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  outline: none;
  border: none;
  appearance: none;
`;

const Panel = styled.div`
  width: 28%;
  background-color: white;
  border-radius: 5px;
  min-height: 300px;
  max-height: 90vh;
  padding: 1rem;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow-y: scroll;

  @media (max-width: 1023px) {
    width: 70%;
  }
`;

const Title = styled(H1)`
  text-align: center;
`;

const H4Title = styled(H4)`
  margin-top: 0.8rem;
  margin-bottom: 0.2rem;
`;

const Item = styled(P)`
  margin-bottom: 4px;
`;

const H3Title = styled(H3)`
  margin-bottom: 0.5rem;
  padding-bottom: 0.2rem;
  display: flex;
  margin-top: 0.8rem;
  align-self: flex-start;
  width: auto;
  border-bottom: 1px solid ${color.black};
`;

const CancelNoteTextArea = styled.textarea`
  margin-top: 0.5rem;
  padding: 0.5rem;
  min-height: 60px;
  min-width: 100%;
  max-width: 100%;
`;

const ConfirmCancelButton = styled(Button)`
  background-color: ${color.redOrange1};
  border: 1px solid ${color.redOrange1};
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const Divider = styled.div`
  margin-bottom: 16px;
`;
